<template>
  <div id="Payout" v-if="render">

    <div class="columns is-vcentered has-background-bluedark2">

      <div class="column is-narrow">
        <!-- <div class="saldo tag is-warning"> -->
          <div class="columns is-gapless has-background-warning" style="padding: 5px; border-radius: 5px;">
            <div class="column is-vcentered has-text-centered">
              <p><span class="has-text-black subtitle">Total Pendentes</span></p>
              <p><span class="has-text-black title">R$ {{ pendentes.toFixed(2) }}</span></p>
            </div>
          </div>
        <!-- </div> -->
      </div>

      <div class="column has-text-centered">
        <!-- <div class="saldo tag" :class="{'is-danger': (saldo - selecionados.bruto) <= 0, 'is-warning': (saldo - selecionados.bruto) == 0, 'is-primary': (saldo - selecionados.bruto) >= 0}"> -->
          <div 
            class="columns is-vcentered is-gapless"
            :class="{'has-background-danger': (saldo - selecionados.bruto) <= 0, 'has-background-warning has-text-black': (saldo - selecionados.bruto) == 0, 'has-background-primary has-text-black': (saldo - selecionados.bruto) >= 0}"
            style="padding: 5px; border-radius: 5px;"
          >
            <div class="column has-text-centered">
              <p><span class="subtitle" :class="{'has-text-black': (saldo - selecionados.bruto) >= 0}">Saldo Atual</span></p>
              <p><span class="title" :class="{'has-text-black': (saldo - selecionados.bruto) >= 0}">R$ {{ saldo.toFixed(2) }}</span></p>
            </div>
            <div class="column is-narrow has-text-centered">
              <p><span class="title" :class="{'has-text-black': (saldo - selecionados.bruto) >= 0}"> - </span></p>
            </div>
            <div class="column has-text-centered">
              <p><span class="subtitle" :class="{'has-text-black': (saldo - selecionados.bruto) >= 0}">Selecionados (Líquido + Taxas)</span></p>
              <!-- <p><span class="title" :class="{'has-text-black': (saldo - selecionados.bruto) >= 0}">R$ {{ selecionados.liquido.toFixed(2) }} + R$ {{ selecionados.taxa.toFixed(2) }}tx.</span></p> -->
              <p><span class="title" :class="{'has-text-black': (saldo - selecionados.bruto) >= 0}">R$ {{ selecionados.liquido.toFixed(2) }} + tx. 1,99%</span></p>
            </div>
            <div class="column is-narrow has-text-centered">
              <p><span class="title" :class="{'has-text-black': (saldo - selecionados.bruto) >= 0}"> = </span></p>
            </div>
            <div class="column has-text-centered">
              <p><span class="subtitle" :class="{'has-text-black': (saldo - selecionados.bruto) >= 0}">Saldo Final</span></p>
              <!-- <p><span class="title" :class="{'has-text-black': (saldo - selecionados.bruto) >= 0}">R$ {{ (saldo - selecionados.bruto).toFixed(2) }}</span></p> -->
              <p><span class="title" :class="{'has-text-black': (saldo - selecionados.bruto) >= 0}">R$ {{ (saldo - (selecionados.liquido * 1.199)).toFixed(2) }}</span></p>
            </div>
          </div>
        <!-- </div> -->
      </div>

      <div 
        class="column is-narrow"
        style="height: 100% !important;"
      >
        <b-button 
          type="is-success" 
          @click="payout('aprovar')" 
          :disabled="checkedRows.length == 0 || (saldo - selecionados.bruto) < 0"
          outline 
          expanded
          style="height: 100% !important;"
        >Aprovar</b-button>
      </div>

      <div 
        class="column is-narrow"
        style="height: 100% !important;"
      >
        <b-button 
          type="is-danger" 
          @click="payout('rejeitar')" 
          :disabled="checkedRows.length == 0" 
          outline 
          expanded
          style="height: 100% !important;"
        >Rejeitar</b-button>
      </div>

    </div>

    <div>

      <b-table
        blueDark2
        :data="payouts"
        :mobile-cards="true"
        default-sort="tradeTime"
        default-sort-direction="desc"
        checkable
        :checked-rows.sync="checkedRows"
        checkbox-position="left"
        detailed
        detail-key="id_reference"
        :show-detail-icon="true" 
      >

        <template slot-scope="props" slot="header">
          <div v-if="props.column.sortable" class="label-table centralize-row">
            <span v-if="props.column.sortable">
              <b-icon icon="sort" pack="fas" size="is-small"></b-icon>
            </span>
            <p>{{ props.column.label }}</p>
          </div>
          <div v-else class="label-table centralize-row">
            <p>{{ props.column.label }}</p>
          </div>
        </template>

        <template slot-scope="props">

          <b-table-column field="tradeTime" label="Dt./Hr." sortable searchable left>
            {{ props.row.tradeTime }}
          </b-table-column>

          <b-table-column field="id_reference" label="Num.Ref." sortable searchable centered>
            {{ props.row.id_reference }}
          </b-table-column>

          <b-table-column field="p_nm_pssa" label="Usuário" sortable searchable left>
            {{ props.row.id_pssa }} - {{ props.row.p_nm_pssa + ' ' + props.row.s_nm_pssa }}<br>
            {{ props.row.cpf_pssa }}<br>
            {{ props.row.mail_pssa }}
          </b-table-column>
<!-- 
          <b-table-column field="transaction_name" label="Beneficiário" sortable searchable left>
            {{ props.row.transaction_name + ' ' + props.row.transaction_last_name }}<br>
            {{ props.row.transaction_document }}<br>
            {{ props.row.transaction_mail }}
          </b-table-column>

          <b-table-column field="transaction_bank" label="Dados Bancários" sortable searchable>
            <div style="width: max-content;">
              <b>Banco / Ag.:</b> {{ props.row.transaction_bank }} / {{ props.row.transaction_bank_branch }}<br>
              <b>Conta:</b> {{ props.row.transaction_account }}<br>
              <b>Tipo Conta:</b> {{ props.row.transaction_account_type }}
            </div>
          </b-table-column>
 -->
          <b-table-column field="status" label="Status" centered searchable>
            <b-tooltip :label="props.row.error" :active="props.row.error" type="is-danger" position="is-bottom" animated>
              <span :class="{'tag is-danger': props.row.status == 'rejeitado', 'tag is-warning': props.row.status == 'pendente', 'tag is-primary': props.row.status == 'em análise', 'tag is-success': props.row.status == 'aprovado' && props.row.type == 3}">
                {{ props.row.status }}
              </span>
            </b-tooltip>
          </b-table-column>

          <b-table-column label="Valor Bruto" right numeric>
            {{ (props.row.valor_deposito + props.row.valor_premio).toFixed(2) }}
          </b-table-column>

          <b-table-column field="taxa" label="Taxa" right numeric>
            {{ props.row.taxa.toFixed(2) }}
          </b-table-column>

          <b-table-column field="valor_liquido" label="Valor Líquido" right numeric>
            {{ props.row.valor_liquido.toFixed(2) }}
          </b-table-column>

        </template>

        <template slot="detail" slot-scope="props">

          <div class="columns is-multiline">
            <div class="column">
              <div class="columns is-multiline is-gapless">
                <div class="column is-full title">
                  BENEFICIÁRIO
                </div>
                <div class="column is-2 subtitle">
                  Nome
                </div>
                <div class="column is-10">
                  {{props.row.transaction_name + ' ' + props.row.transaction_last_name}}
                </div>
                <div class="column is-2 subtitle">
                  Documento
                </div>
                <div class="column is-10">
                  {{props.row.transaction_document}}
                </div>
                <div class="column is-2 subtitle">
                  E-mail
                </div>
                <div class="column is-10">
                  {{props.row.transaction_mail}}
                </div>
              </div>
            </div>
            <div class="column">
              <div class="columns is-multiline is-gapless">
                <div class="column is-full title">
                  DADOS BANCÁRIOS
                </div>
                <div class="column is-2 subtitle">
                  Banco/Ag.
                </div>
                <div class="column is-10">
                  {{ props.row.transaction_bank }} / {{ props.row.transaction_bank_branch }}
                </div>
                <div class="column is-2 subtitle">
                  Conta
                </div>
                <div class="column is-10">
                  {{ props.row.transaction_account }}
                </div>
                <div class="column is-2 subtitle">
                  Tipo Conta
                </div>
                <div class="column is-10">
                  {{ props.row.transaction_account_type }}
                </div>
              </div>
            </div>
          </div>
<!-- 
          <table>
            <tr>
              <td><b class='is-size-6'>Beneficiário</b></td>
              <td>{{props.row.transaction_name + ' ' + props.row.transaction_last_name}}</td>
              <td><b class='is-size-6'>CPF</b></td>
              <td>{{props.row.transaction_document}}</td>
              <td><b class='is-size-6'>E-mail</b></td>
              <td>{{props.row.transaction_mail}}</td>
            </tr>
            <tr>
              <td><b class='is-size-6'>Banco/Ag.</b></td>
              <td>{{ props.row.transaction_bank }} / {{ props.row.transaction_bank_branch }}</td>
              <td><b class='is-size-6'>Conta</b></td>
              <td>{{ props.row.transaction_account }}</td>
              <td><b class='is-size-6'>Tipo Conta</b></td>
              <td>{{ props.row.transaction_account_type }}</td>
            </tr>
          </table>
 -->
        </template>

        <template slot="empty">
            <section class="section">
                <div class="content has-text-grey has-text-centered">
                    <p>
                        <b-icon
                            icon="emoticon-sad"
                            size="is-large">
                        </b-icon>
                    </p>
                    <p>Nenhum Registro.</p>
                </div>
            </section>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Moment from 'moment'
export default {
  name: "Payout",
  data() {
    return {
      render: false,
      saldo: 0,
      pendentes: 0,
      taxas: 0,
      payouts: [],
      checkedRows: [],
      acao: "",
      motivo: "" 
    };
  },
  computed: {
    ...mapGetters(["userId"]),
    selecionados(){
      let bruto = 0
      let taxa = 0
      let liquido = 0
      for (const check of this.checkedRows) {
        bruto += (check.valor_deposito + check.valor_premio)
        taxa += check.taxa
        liquido += check.valor_liquido
      }
      return {bruto, taxa, liquido}
    }
  },
  async created() {
    this.updateSaldo()
    this.updateTable()
  },
  methods: {
    async updateSaldo(){

      let loader = this.$loading.show()

      this.$http.get(url + '/payment/paymee/balance')
      .catch(err => {
        console.log(err)
        loader.hide()
      })
      .then(async response => {

        let result = false

        if (response.data.data) {
          let data = response.data.data
          if (data.message){
            if (data.message == 'success'){
              if (data.balance || data.balance == 0){
                result = true
                this.saldo = data.balance
              }
            }
          }
        } else {
          console.error('ERROR_PAYMEE_BALANCE')
        }

        if (!result){
          this.$toasted.error("Ocorreu um problema na integração da consulta do saldo da conta virtual!", {
            duration: 3000,
            position: "top-center"
          });
        }
        loader.hide()
      })

    },
    async updateTable(){

      let loader = this.$loading.show()

      this.$http.post(url + '/ligas/trades/',{
        params: [
          {field: 'status_transaction', operation: 'NOT IN', content: "('paid','approved','pending','rejected')"},
          {field: 'id_type_trade', operation: '=', content: 5}
        ]
      })
      .catch(err => {
        console.error('HTTP_ERROR')
        loader.hide()
      })
      .then(response => {
        if (response.data.data) {
          this.pendentes = 0
          this.taxas = 0
          this.payouts = response.data.data
          this.payouts = this.payouts.map((el,index) => {

            let tradeTime = Moment(new Date(el.created_at))
            tradeTime = tradeTime.format('DD/MM/YYYY - HH:mm:ss')
            
            let status = ''
            if (el.status_transaction){
              status = el.status_transaction.toLowerCase()
              if (status == "approved" || status == "paid"){
                status = "aprovado"
              } else if (status == "rejected") {
                status = "rejeitado"
              } else if (status == "pending") {
                status = "pendente"
              } else if (status == "balance") {
                status = "em análise"
              }
            }

            el.valor_deposito = Math.abs(el.valor_deposito)
            el.valor_premio = Math.abs(el.valor_premio)
            el.valor_liquido = Math.abs(el.valor_liquido)
            el.taxa = Math.abs(el.taxa)

            this.pendentes += el.valor_liquido
            this.taxas += el.taxa

            return {...el, tradeTime: tradeTime, status: status}
          })
          this.checkedRows = []
          this.render = true
        }
        loader.hide()
      })
    },
    async payout(action){

      this.acao = action

      try {

        if (this.acao == 'aprovar'){
          await this.updateSaldo()
        }

        if (this.acao == 'aprovar' && (this.saldo - this.selecionados.bruto) < 0){

          this.$buefy.dialog.alert({
            title: "ATENÇÃO!",
            message: "Saldo em conta insuficiente para realizar a operação.",
            type: "is-danger",
            hasIcon: true
          });

        } else if (this.acao == 'rejeitar' && this.motivo == ''){

          this.$buefy.dialog.prompt({
            title: "ATENÇÃO!",
            message: "Informe o motivo da rejeição",
            type: "is-warning",
            hasIcon: true,
            inputAttrs: {
                type: 'text',
                placeholder: 'Motivo'
            },
            trapFocus: true,
            confirmText: "Confirmar",
            cancelText: "Cancelar",
            onConfirm: (value) => {
              this.motivo = value
              this.payout('rejeitar')
            }
          });

        } else {

          let loader = this.$loading.show()

          this.isLoading = true;

          this.$http.post(url + '/payment/payout/batch',{
            acao: this.acao, motivo: this.motivo, trades: this.checkedRows
          })
          .catch(err => {
            console.log(err)
            loader.hide()
          })
          .then(async response => {

            if (response.data.data) {

              let results = response.data.data

              let msgError = ""
              if (results){
                for (const result of results) {
                  if (result.error){
                    msgError += 
                    ((msgError !== "") ? "<br> * " : "") + 
                    ((result.id) ? "Id: " + result.id : '') + 
                    ((result.reference) ? " - Ref.: " + result.reference : '') + " - Erro: " + result.error 
                  }
                }
              }

              this.isLoading = false;
              loader.hide()

              if (msgError !== ""){
                this.$buefy.dialog.alert({
                  title: "ATENÇÃO!",
                  message: "Ocorreu um problema na "+(this.acao == 'aprovar' ? "aprovação" : "rejeição")+":"+msgError,
                  type: "is-danger",
                  hasIcon: true,
                  onConfirm: () => {
                    this.updateSaldo()
                    this.updateTable()
                  }
                });
              } else {
                this.$buefy.dialog.alert({
                  title: (this.acao == 'aprovar') ? "APROVADO!" : "REJEITADO!",
                  message: "Payout "+(this.acao == 'aprovar' ? 'aprovado' : 'rejeitado')+" com sucesso!",
                  type: "is-success",
                  hasIcon: true,
                  onConfirm: () => {
                    this.updateSaldo()
                    this.updateTable()
                  }
                });
              }
            }

          })

        }
      } catch (err) {
        if (loader) loader.hide()
        this.isLoading = false;
        console.log(err);
        this.$toasted.error("Ocorreu um problema ao tentar processar o Payout!", {
          duration: 3000,
          position: "top-center"
        });
      }
    }
  }
};
</script>
